.document-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;

    .document-header {
        display: flex;
        flex-direction: row;
        width: 100%;

        .extraction-status {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .magic-wand-icon {
                width: 1.3rem;
                height: 1.3rem;
                margin: 0 0.5rem;
    
                &.processing {
                    @keyframes fade {
                        0% { opacity: 1; }
                        50% { opacity: 0.2; }
                        100% { opacity: 1; }
                    }
                    animation: fade 2s infinite;
                }
            }

            .processing-status {
                font-size: 0.9rem;
                font-style: italic;
                text-align: center;
            }
        }
    }

    .document-properties {
        margin: 0;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
        grid-auto-rows: auto;
        grid-gap: 0.5rem;
        padding: 0;
    }
}