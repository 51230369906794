.create-claim-button {
    border-radius: 10px;
    padding: 0 2rem;
    color: black;
    text-align: center;
    font-size: 1em;
    font-weight: 400;
    height: 2.5rem;
    width: fit-content;
    border: none;
    background-color: #DA9A23;
    transition: background-color 0.2s;
    color: white;
    cursor: pointer;

    &:hover {
        background-color: #e7a937;
    }
    &:active {
        background-color: #ebb756;
    }
}